//
// CSS Variables
//

:root,
[data-bs-theme='white'] {
  // Select2

  // Prismjs
  --#{$prefix}prismjs-bg: #{$prismjs-bg};

  --#{$prefix}prismjs-border: #{$prismjs-border};

  --#{$prefix}prismjs-btn-bg: #{$prismjs-btn-bg};
  --#{$prefix}prismjs-btn-bg-hover: #{$prismjs-btn-bg-hover};
  --#{$prefix}prismjs-btn-color: #{$prismjs-btn-color};
  --#{$prefix}prismjs-btn-color-hover: #{$prismjs-btn-color-hover};

  --#{$prefix}prismjs-scrollbar-color: #{$prismjs-scrollbar-color};
  --#{$prefix}prismjs-scrollbar-color-hover: #{$prismjs-scrollbar-color-hover};
}

[data-bs-theme='dark'] {
  // Prismjs
  --#{$prefix}prismjs-bg: #{$prismjs-bg-dark};

  --#{$prefix}prismjs-border: #{$prismjs-border-dark};

  --#{$prefix}prismjs-btn-bg: #{$prismjs-btn-bg-dark};
  --#{$prefix}prismjs-btn-bg-hover: #{$prismjs-btn-bg-hover-dark};
  --#{$prefix}prismjs-btn-color: #{$prismjs-btn-color-dark};
  --#{$prefix}prismjs-btn-color-hover: #{$prismjs-btn-color-hover-dark};

  --#{$prefix}prismjs-scrollbar-color: #{$prismjs-scrollbar-color-dark};
  --#{$prefix}prismjs-scrollbar-color-hover: #{$prismjs-scrollbar-color-hover-dark};
}
