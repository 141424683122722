//
// Col form label
//

.col-form-label {
  @include font-size($form-label-font-size);
}

.form-subtitle {
  color: #8a9099;
  font-size: 16px;
}
.form-subsection {
  margin-bottom: 5px;
}
.form-subvalue {
  font-size: 16px;
  margin-left: 6px;
}
