//
// Nav
//

// Nav pills
.nav-pills {
  .nav-item {
    margin-right: 0.5rem;

    &:last-child {
      margin-right: 0;
    }
  }
}

// Stretch items
.nav-stretch {
  align-items: stretch;
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  .nav-item {
    display: flex;
    align-items: stretch;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .nav-link {
    display: flex;
    align-items: center;
  }
}

// Nav group
.nav-group {
  padding: 0.35rem;
  @include border-radius($border-radius);
  background-color: var(--#{$prefix}gray-100);

  // Outline nav group
  &.nav-group-outline {
    background-color: transparent;
    border: 1px solid var(--#{$prefix}border-color);
  }

  // Fluid option
  &.nav-group-fluid {
    display: flex;

    >label,
    >.btn {
      position: relative;
      flex-shrink: 0;
      flex-grow: 1;
      flex-basis: 0;
    }

    >label {
      margin-right: 0.1rem;

      >.btn {
        width: 100%;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.nav-tabs .nav-link {
  border-top-right-radius: 100px;
  border-top-left-radius: 30px;
  background-color: white;
  opacity: 0.6;
  // border-top-left-radius: 0px;
  font-size: 20px;
  color: #8a9099;
  width: 185px;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: black;
  font-weight: 300;
  border-left: solid;
  border-color: #F0F1F2;
  border-width: 0.5px;
  border-bottom: 0 !important;
  border-style: solid;
  opacity: 1 !important;
}

.nav-custom-tabs .nav-link.active,
.nav-custom-tabs .nav-custom-item.show .nav-link {
  color: black;
  opacity: 1 !important;
  font-weight: 300;
  border-bottom: solid;
  border-left: none;
}