//
// Theme style
//

// Initialize
@import 'init';

// Components
@import './core/components/components';
@import 'components/components';

// Layout
@import './core/layout/base/layout';
@import 'layout/layout';


// .customer_tab {
//     border-radius: 999 !important;
//     border: 10;
//     background-color: transparent;
// }

.custom-btn-success {
    color: #50cd89 !important;
    background-color: #50cd8921 !important;
    border-radius: 10px !important;
    font-weight: 600 !important;

    &:hover {
        background-color: #50cd8961 !important;
    }
}

.custom-btn-danger {
    color: #FF001F !important;
    background-color: #f1416c21 !important;
    border-radius: 10px !important;
    font-weight: 600 !important;

    &:hover {
        background-color: #f1416c61 !important;
    }
}