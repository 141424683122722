//
// Timeline
//

// Base
.timeline {
  // Item
  .timeline-item {
    position: relative;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: flex-start;

    &:last-child {
      .timeline-line {
        bottom: 100%;
      }
    }
  }

  // Line
  .timeline-line {
    display: block;
    content: ' ';
    justify-content: center;
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    bottom: 0;
    transform: translate(50%);
    border-left-width: 1px;
    border-left-style: dashed;
    border-left-color: var(--#{$prefix}gray-300);
  }

  // Icon
  .timeline-icon {
    z-index: 1;
    flex-shrink: 0;
    margin-right: 1rem;
  }

  // Content
  .timeline-content {
    width: 100%;
    overflow: auto;
    margin-bottom: 1.5rem;
  }

  // Vertical center
  &.timeline-center {
    .timeline-item {
      align-items: center;

      &:first-child {
        .timeline-line {
          top: 50%;
        }
      }

      &:last-child {
        .timeline-line {
          bottom: 50%;
        }
      }
    }
  }
}

.timelineHorizontal {
  margin: 50px 60px;
  height: 4px;
  background-color: #ccc;
  .timeline-progress {
    height: 100%;
    background-color: #FD8D27;
  }

  .timeline-items {
    margin-left: -10px;
    margin-right: -10px;
    margin-top: -12px;
    display: flex;
    justify-content: space-between;
  }

  .timeline-item {
    position: relative;
  }

  .timeline-item::before {
    position: absolute;
    left: -4px;
    top: -15px;
    width: 20px;
    height: 20px;
    //background-color: #ccc;
    display: block;
    border-radius: 100%;
  }

  .timeline-content {
    position: absolute;
    top: -16px;
    left: -19px;
    //transform: translateX(-50%);
    background-color: #243c43;
    //width: 100px;
    padding: 10px;
    border-radius: 50px;
    text-align: center;
  }
  .timeline-title {
    position: absolute;
    top: 40px;
    left:-50px;
    width:120px;
    text-align: center;
  }

  .timeline-item.active::before {
    background-color: #FD8D27;
  }

  .timeline-item.active .timeline-content {
    color: #fff;
    background-color: #FD8D27;
  }

  .timeline-item.active .timeline-content::before {
    background-color: #FD8D27;
  }
  //done************************************
  .timeline-item.done::before {
    z-index: 500000;
    left: -19px;
    content: url('../../../../../../public/media/svg/tarcking/check.svg');
  }

  .timeline-item.done .timeline-content {
    color: #fff;
    background-color: #243c43;
  }

  .timeline-item.done .timeline-content::before {
    background-color: #243c43;
  }
  //waiting******************************
  .timeline-item.waiting::before {
    background-color: #cfcfcf;
  }

  .timeline-item.waiting .timeline-content {
    color: #fff;
    background-color: #cfcfcf;
  }

  .timeline-item.waiting .timeline-content::before {
    background-color: #cfcfcf;
  }
}
